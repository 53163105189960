<script setup>
  const emit = defineEmits(['deleted'])

  const props = defineProps({
    name: {
      type: String,
      required: false
    },
    message: {
      type: String,
      required: false,
      default: 'Delete <strong>{name}</strong>?'
    },
    store: {
      type: Object,
      required: true
    },
    bulkDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    itemsToDelete: {
      type: Array,
      required: false,
      default: () => []
    }
  })

  const { deleteDialog, deleteLoading, deleteItem } = storeToRefs(props.store)

  async function deleteObj() {
    if (props.bulkDelete && props.itemsToDelete.length) {
      const deletedItems = props.itemsToDelete
      await props.store.bulkDelete(props.itemsToDelete)
      props.store.toggleDeleteDialog()
      emit('deleted', deletedItems)
    } else {
      const deletedItemID = deleteItem.value.id
      const deletedItem = await props.store.delete()
      if (deletedItem) {
        props.store.toggleDeleteDialog()
        deletedItem.id = deletedItemID
        emit('deleted', deletedItem)
      }
    }
  }

  const message = computed(() => {
    if (props.bulkDelete) {
      return `Are you sure you want to delete these ${props.itemsToDelete.length} items?`
    }
    let showName = ''
    if (props.name) {
      showName = props.name
    } else {
      showName = deleteItem.value?.name
    }
    return props.message.replace(/{name}/g, showName)
  })
</script>

<template>
  <v-dialog v-model="deleteDialog" max-width="400" @click:outside.stop="store.toggleDeleteDialog()">
    <v-card class="pa-3">
      <v-card-title>
        <span>Delete</span>
      </v-card-title>
      <v-card-text class="pt-3 pl-4" v-html="message"></v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn @click="store.toggleDeleteDialog()" variant="text" color="primary">Cancel</v-btn>
        <v-btn :loading="deleteLoading" color="red darken-1" text @click="deleteObj()">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
