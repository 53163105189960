<script setup lang="ts">
  import { RHDHVHeader, useEventsBus } from '@rhdhv/vue-component-library'
  import { storeToRefs } from 'pinia'
  import router from '@/router'
  import { useProjectStore } from '@/stores/project'
  import { computed } from 'vue'

  const { bus } = useEventsBus()
  const authStore = inject('useAuthStore')
  const projectStore = useProjectStore()
  const loading = ref(false)

  const { currentItem: currentProject } = storeToRefs(projectStore)
  const { user } = storeToRefs(authStore)

  const route = useRoute()
  const currentRouteName = computed(() => route.name)

  async function logout() {
    await authStore.logout()
    await router.push({ name: 'auth-login' })
  }

  async function onLogoClicked() {
    await router.push({ name: 'project-index' })
  }

  const isProjectDetail = computed(() => currentRouteName.value == 'project-detail')

  watch(
    () => bus.value.get('loading'),
    (val) => {
      val && (loading.value = val[0])
    }
  )
</script>
<template>
  <suspense>
    <RHDHVHeader
      app
      v-if="user"
      :user="user"
      :title="'GEOProfile'"
      :loading="loading"
      :menu-color="'#a9a9a9'"
      image-src="/rhdhv-logo.png"
      @logout="logout"
      @logo-click="onLogoClicked"
    >
      <template v-slot:toolbar-prepend>
        <v-app-bar-nav-icon
          class="ml-1"
          small
          color="white"
          @click="projectStore.toggleDrawer"
          v-if="isProjectDetail"
        ></v-app-bar-nav-icon>
      </template>

      <template v-slot:breadcrumbs-items>
        <v-breadcrumbs-item>
          <router-link
            class="text-white toolbar-text text-decoration-none"
            :to="{ name: 'project-index' }"
          >
            Projects
          </router-link>
        </v-breadcrumbs-item>

        <template v-if="currentProject?.id && isProjectDetail">
          <v-breadcrumbs-divider />
          <v-breadcrumbs-item>
            <router-link
              class="text-white toolbar-text text-decoration-none"
              :to="{ name: 'project-index' }"
            >
              {{ currentProject?.name }}
            </router-link>
          </v-breadcrumbs-item>
        </template>
      </template>
    </RHDHVHeader>
  </suspense>
</template>

<style scoped></style>
