<script setup lang="ts">
  import CreateOrUpdateDialog from '@/components/dialog/CreateOrUpdateDialog.vue'
  import DeleteDialog from '@/components/dialog/DeleteDialog.vue'
  import { onMounted, onUnmounted, ref, watch } from 'vue'
  import { storeToRefs } from 'pinia/dist/pinia'
  import { useProjectStore } from '@/stores/project'
  import { useAuthorizationStore } from '@/stores/authorization'
  import { ProjectIndex } from '@rhdhv/vue-component-library'
  import router from '@/router'
  import { useDjangoAuthStore } from '@/stores/authDjango'
  import type { Project } from '@/types/storeTypes'

  const authorizationStore = useAuthorizationStore()
  const store = useProjectStore()
  const projectUserStore = useProjectUserStore()
  const authStore = useDjangoAuthStore()
  const { items: roles } = storeToRefs(authorizationStore)
  const { user: currentUser } = storeToRefs(authStore)

  const {
    items,
    createUpdateDialog,
    deleteDialog,
    deleteItem,
    fetchLoading: loading
  } = storeToRefs(store)
  const canEditProject = ref(false)
  const canDeleteProject = ref(false)
  watch(createUpdateDialog, () => {
    if (!createUpdateDialog.value) {
      store.setCurrentItem({})
    }
  })

  watch(deleteDialog, () => {
    if (!deleteDialog.value) {
      store.setDeleteItem({})
    }
  })

  async function updateProject() {
    const response = await store.postOrUpdate()
    if (response) {
      store.toggleCreateUpdateDialog()
    }
  }

  async function deleteProject() {
    const response = await store.delete(deleteItem)
    if (response) {
      store.toggleDeleteDialog()
    }
  }

  const updatePermissions = async (projects: Project[]) => {
    for (const item of projects) {
      const response = await projectUserStore.fetchItems({ search: item.id })
      const currentProjectUser = response.find(
        (response: any) => response.user === currentUser.value?.id
      )
      const currentRole = roles.value.find((role) => role.id === currentProjectUser?.role)
      canEditProject.value = currentRole?.permissions?.includes('change_project') || false
      canDeleteProject.value = currentRole?.permissions?.includes('delete_project') || false
    }
  }
  onMounted(async () => {
    if (!items || !items.value.length) {
      await store.fetchOptions()
      await store.fetchItems({})
    }
    await authorizationStore.fetchItems({})
    if (items.value.length) {
      await updatePermissions(items.value)
    }
  })

  onUnmounted(() => {
    projectUserStore.resetState()
    authorizationStore.resetState()
  })

  const navigateToProject = (projectID: number) => {
    router.push({ name: 'project-detail', params: { id: projectID } })
  }

  function onAddClicked() {
    store.setCurrentItem({})
    store.toggleCreateUpdateDialog()
  }

  function onUpdate(item) {
    store.toggleCreateUpdateDialog(item)
  }

  function onDelete(item) {
    store.toggleDeleteDialog(item)
  }
</script>

<template>
  <div class="fill-height">
    <ProjectIndex
      title="GEOProfile"
      :items="items"
      :loading="loading"
      @navigateToProject="navigateToProject"
      @add-item="onAddClicked"
      @delete-item="onDelete"
      @edit-item="onUpdate"
    />
    <CreateOrUpdateDialog :store="store" name="project" @save="updateProject" />
    <DeleteDialog :store="store" :name="deleteItem?.name" @delete="deleteProject" />
  </div>
</template>

<style scoped>
  :deep(.v-table__wrapper table) {
    border: 1px solid lightgrey;
  }
</style>
